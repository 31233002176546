.Spinner-Page {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    z-index: 14;
    background-color: white;
    animation: fadeOut 1.5s forwards;
    animation-delay: 1.5s;
}

.Spinner-Page p {
    font-family: 'Alegreya Sans SC', sans-serif;
    font-size: 23px;
}

.Spinner-Container {
    width: 70px;
    height: 70px;
    border-radius:  50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    animation: rotate 0.8s infinite ease;
}

.Circle {
    width: 15px;
    height: 15px;
    background-color: black;
    border-radius: 50%;
}

.Circle:nth-child(2) {
    width: 20px;
    height: 20px;
}


@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}