.MenuBar-Container {
    width: 120px;
    height: 80px;
    position: absolute;
    z-index: 9;
    top: 50px;
    left: 70px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
}

.Hamburger {
    width: 50px;
    height: 45px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}


.bar {
    width: 100%;
    height: 7px;
    background-color: white;
    border-radius: 1rem;

}


.black {
    background-color: black;
}



.font-black {
   visibility: hidden;
}



.MenuBar-Container-Hide {
    height: 0px;
    display: none;
}

.MenuBar-Container p {
    color: white;
    font-family: 'Open sans', sans-serif;
    margin-left: 10px;
    font-size: 19px;
    
}


@media only screen and (min-width: 250px) {
    .MenuBar-Container {
        position: fixed;
        left: 25px;
        
    }
}


@media only screen and (min-width: 700px) {
    .MenuBar-Container {
        left: 50px;
    }
}


@media only screen and (min-width: 1200px) {
    .MenuBar-Container {
        left: 70px;
    }
}