.Questionaire-Container {
  
}



.Left-Image {
    width: 59%;
    height: 100vh;
    background: url(../../images/image8.jpeg);
    background-size: cover;
    background-position: center;
    position: fixed;
    opacity: 0.6;
    float: left;
}

.Right-Content {
    width: 40%;
    height: 100vh;
    float: right;

}

.Questionaire {
    width: 100%;
    height: 550px;
    
}

.RSVP-Guests-Container {
    width: 80%;
    height: fit-content;
}



.Questionaire-Greeting {
    font-family: 'Alegreya Sans SC', sans-serif;
    text-align: center;
    margin-top: 70px;
    margin-bottom: 0px;
}

.Questionaire-Header {
    text-align: center;
    font-family: 'Alegreya Sans SC', sans-serif;
    font-weight: 900;
    margin-top: 70px;
}

.Question-Text {
    width: 80%;
    margin: 0 auto;
    margin-top: 50px;
    font-size: 18px;
    line-height: 30px;
    text-indent: 20px;
    font-family: 'Open sans', sans-serif;
}




/*
    Media Queries
*/

@media only screen and (min-width: 250px) {
    .Left-Image {
        width: 0%;
    }

    .Right-Content {
        width: 100%;
        
    }

    .Questionaire {
        width: 85%;
        max-width: 450px;
    }
}


@media only screen and (min-width: 1100px) {
    .Left-Image {
        display: block;
        width: 59%;
    }

    .Right-Content {
        width: 40%;
    }
}
