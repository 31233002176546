.Dashboard {
    width: 100vw;
    height: 100vh;
    position: relative;
}

.Dashboard-Left {
    height: 100vh;
    width: 60%;
    float: left;
    background: url(../../../images/Beach.jpeg);
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.8;
}

.Dashboard-Right {
    height: 100vh;
    width: 40%;
    float: right;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
}

.Dashboard-Header {
    text-align: center;
    font-family: 'Alegreya Sans SC', sans-serif;
}

.Dashboard-Options {
    width: 100%;
    height: 99vh;
    float: left;
    
}

.Dashboard-Buttons {
    border: 1px solid black;
    width: 70%;
    margin: 0 auto;

    display: flex;
    justify-content: center;
}

.Dashboard-Sections-Container {
    width: 100%;
    height: 80vh;
    border: 1px solid red;
    display: flex;
    justify-content: center;
    flex-direction: column;
}


.Notification-Center {
    width: 30%;
    background: url(../../../images/Beach.jpeg);
    background-size: cover;
    background-position: center;
    height: 100vh;
    float: right;
    opacity: 0.64;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}


.Guests-Container {
    width: 70%;
    margin: 10px auto;
    position: relative;
}

.Guests-Container-Header {
    position: absolute;
    left: 0px;
    top: -20px;
    font-family: 'Alegreya Sans SC', sans-serif;
}

.Guests-Container-Counter {
    padding-right: 5px;
    right: 0px;
    top: -20px;
    text-align: right;
    font-family: 'Open sans', sans-serif;
}



.Accepted-Guests {
    height: 300px;
    overflow-y: scroll;
    border: 1px solid black;
    border-radius: 0.3rem;
}



/* width */
::-webkit-scrollbar {
    width: 7px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: black;
    border-radius: 10px;
  }