
/*
    Landing Page; Right side global Styles
*/
.Section-Text {
    width: 80%;
    line-height: 25px;
    text-indent: 25px;
    margin: auto;
    margin-bottom: 20px;
    font-size: 14px;
}

html {
    scroll-behavior: smooth;
}


.Left-Content {
    width: 61%;
    height: 100vh;
    border: 1px solid black;
    float: left;
}


.Header-Text {
    font-family: 'Alegreya Sans SC', sans-serif;
}

.General-Font {
    font-family: 'Open sans', sans-serif;
}

@keyframes animateClose {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        height: 0;
        display: none;
    }
}

@keyframes animateHeight {
    from {
        height: 0%;
    }
    to {
        height: 500px;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes fadeOut2 {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@media only screen and (min-width: 900px) {
    .Section-Text {
        font-size: 17px;
    }
}