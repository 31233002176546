.Schedule-Container {
    width: 100%;
    height: fit-content;
    margin-bottom: 100px;
}


.Schedule-Container h1 {
    text-align: center;
    font-family: 'Alegreya Sans SC', sans-serif;
    font-size: 25px;
    
}

.Schedule-Container hr {
    width: 25%;
    margin: 20px auto;
}

.Event-Container {
    width: 80%;
    margin: 0 auto;
    margin-top: 50px;
    height: fit-content;
}


.Event-Date {
    font-size: 19px;
    font-family: 'Alegreya Sans SC', sans-serif;
}


.Events-Box {
    width: 70%;
    height: 300px;
    border: 1px solid black;
    margin: 0 auto;
}


.Event {
    width: 80%;
    height: 200px;
    border: 1px solid  red;
    margin: 0 auto;
    padding: 0px 20px;
    display: flex;
    justify-content: space-between;
}


.Event-Details {

}

.Time {
    font-family: 'Alegreya Sans SC', sans-serif;
    
}

.Description {
    height: fit-content;
    width: 50%;
    border: 1px solid black;
}


.Schedule-Grid {
    display: grid;
    grid-template-columns: 40% 60%;
    grid-template-rows: fit-content;
    font-family: 'Open sans', sans-serif;
}

.Time-Section {
    grid-row: 0;
    text-align: center;
    text-indent: 30px;
}

.Right-Details {
    margin: 10px;
}

.Right-Details p {
    text-align: center;
    margin: 0px;
}

.Right-Details p:nth-child(2) {
    margin-top: 30px;
    margin-bottom: 20px;
}



.Right-Details p:last-child {
    margin-top: 10px;
    margin-bottom: 20px;
   
}
