.Credits-User {
    font-family: 'Alegreya Sans SC', sans-serif;
    text-align: center;
    width: 50%;
    text-align: center;
    margin: auto;
}

.Credits {
    width: 100%;
    margin-top: 50px;
}

.Credits-Contacts {
    width: 50%;
    max-width: 250px;
    height: 50px;
    margin: auto;
    margin-top: -30px;
    margin-bottom: 100px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.Contact {
    cursor: pointer;
    color: rgba(70,70,70,1);

}