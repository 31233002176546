.Slide-Image{
    width: 100%;
    height: 100vh;
}

.yeet {
    width: 50%;
}

.Image1 {
    background: url(../../images/Carousel/image1.jpeg);
    background-size: cover;
    background-position: center;
}

.Image2 {
    background: url(../../images/Carousel/image2.jpeg);
    background-size: cover;
    background-position: center;
}

.Image3 {
    background: url(../../images/Carousel/image3.jpeg);
    background-size: cover;
    background-position: center;
    
}

.Image4 {
    background: url(../../images/Carousel/image4.jpeg);
    background-size: cover;
    background-position: center;
    
}

.Image5 {
    background: url(../../images/Carousel/image5.jpeg);
    background-size: cover;
    background-position: center;
    
}

.Image6 {
    background: url(../../images/Carousel/image6.jpeg);
    background-size: cover;
    background-position: center;
    
}

.Image7 {
    background: url(../../images/Carousel/image7.jpeg);
    background-size: cover;
    background-position: center;
    
}



@media only screen and (min-width: 250px) {
    .Image2 {
       
        background-position: -700px 0px;
       
        
    }

    .Image3 {

    }


    .Image5 {
        background-position: -700px 0px;
    }
}


@media only screen and (min-width: 600px) {
    .Image2, .Image5 {
        background-position: -550px 0px;
       
    }
}

@media only screen and (min-width: 900px) {
    .Image2, .Image5 {
        background-position: center;
    }
}