.Guest {
    width: 95%;
    margin: 10px auto;
    border: 1px solid black;
    border-radius: 0.5rem;

    cursor: pointer;
}

.Guest-Name {
    margin-left: 20px;
}