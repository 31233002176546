.Covid-Notice {
    position: fixed;
    width: 100%;
    height: 120px;
    background-color: white;
    z-index: 13;
    box-shadow: 5px 10px 10px rgba(0,0,0,0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}

.Covid-Notice p {
    width: 85%;
    margin-left: 50px;
    font-size: 18px;
    line-height: 30px;
    text-indent: 20px;
}

.Message-Hide {
    animation: fadeOut2 1s forwards; 
}

.Covid-Notice-Close {
    cursor: pointer;
    margin-right: 40px;
}

.Covid-Notice-Hide {
    animation: animateClose 1s forwards;
    animation-delay: 1s;
}


@keyframes closeMenu {
    from {
        opacity: 1;
        height: 120px;
    }

    to {
        opacity: 0;
        height: 0px;
    }
}



/*
    Mobile Devices
*/
@media only screen and (min-width: 250px) {
    .Covid-Notice {
        height: fit-content;
    }

    .Covid-Notice p {
        font-size: 10px;
        line-height: 22px;
        text-align: left;
        width: 75%;
    }


    .Covid-Notice-Close {
        margin-right: 20px;
    }
}

@media only screen and (min-width: 700px) {
    .Covid-Notice {
        height: 120px;
    }


    .Covid-Notice p {
        font-size: 15px;
        width: 85%;
    }


}



@media only screen and (min-width: 1200px) {
    .Covid-Notice p {
        font-size: 17px;
        width: 80%;
        text-align: center;
        line-height: 28px;
    }
}