.Menu-Options {
    width: 500px;
    height: 500px;
    position: absolute;
    top: 50px;
   
    z-index: 8;
    background-color: white;

    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: animateHeight 1s ease;
}

.Menu-Option {
    width: fit-content;
    height: 50px;
    font-size: 30px;
    text-align: center;
    font-family: 'Alegreya Sans SC', sans-serif;
    margin: 5px auto;
    cursor: pointer;
    color: black;
    text-decoration: none;
    
    opacity: 0;
    animation: fadeIn 1s;
    -webkit-animation: fadeIn 1s;
    -moz-animation: fadeIn 1s;
    animation-delay: 1s;
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
}

.Menu-Option::after {
    width: 0%;
    height: 2px;
    margin: 3px auto;
    content: '';
    display: block;
    background-color: black;
    transition: all 0.4s ease-out;
}

.Menu-Option:hover::after {
    width: 70%;
}



.Menu-Close {
    position: absolute;
    top: -5px;
    right: 15px;
    cursor: pointer;
    font-size: 30px;
    font-family: 'Open sans', sans-serif;

    opacity: 0;
    animation: fadeIn 1s;
    -webkit-animation: fadeIn 1s;
    -moz-animation: fadeIn 1s;
    animation-delay: 1s;
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
}



.Menu-Show {
    display: flex;
}

.Menu-Hide {
    animation: animateClose 1s forwards;
    animation-delay: 0.3s;
}

.Menu-Option-Hide {
    animation: fadeOut 0.3s forwards;
}

@media only screen and (min-width: 250px) {
    .Menu-Options {
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        width: 90%;
        box-shadow: 5px 10px 10px rgba(0,0,0,0), -5px 10px 10px rgba(0,0,0,0.2);
    }
}


@media only screen and (min-width: 1200px) {
    .Menu-Options {
        left: 50px;
        width: 500px;
        transform: translateX(0%);
    }
}