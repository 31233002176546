.Answer-Container {
    position: relative;
    width: 80%;
    height: fit-content;
    margin: 20px auto;
}


.Answer-Choice {
    text-align: center;
    width: 100%;
    height: 50px;
    border: 1px solid black;
    font-size: 19px;
    margin: 0 auto;
    margin-top: 20px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 0.15s ease-in;
}


.Answer-Choice:hover {
    background-color: black;
    color: white;
}

.Selected {
    background-color: rgba(40,40,40);
    color: white;
}

.Question-Font {
    font-size: 20px;
    font-family: 'Open sans', sans-serif;
}


.Answer-Container:nth-child(2) {
   
    margin-bottom: 75px;
}

@media only screen and (min-width: 250px) {
    .Answer-Choice {
        width: 70%;
        max-width: 300px;
        font-size: 14px;
        padding: 10px;
        height: 40px;
    }

    .Question-Font {
        font-size: 14px;
        margin-bottom: 30px;
        width: 100%;
    }
}


@media only screen and (min-width: 700px) {
    .Answer-Choice {
        min-width: 300px;
    }
}