.Travel-Section {
    width: 100%;
    margin-bottom: 100px;
}

.Travel-Section h1 {
    text-align: center;
}

.Travel-Step {
    text-align: left;
    width: 80%;
    margin: auto;
    font-size: 20px;
    font-family: 'Open sans', sans-serif;
}


.Book-Button {
    border: 1px solid black;
    background-color: white;
    text-decoration: none;
    color: black;
    font-size: 22px;
    padding: 10px;
    border-radius: 0.3rem; 
    transition: all 0.2s ease;
    margin: auto;
    font-family: 'Open Sans', sans-serif;
}

.Book-Button:hover {
    background-color:rgb(40,40,40);
    color: white;
}

.Travel-List {
    width: 70%;
    margin:  auto;
    margin-bottom: 40px;

    list-style-type: disc;
}


