/* **************************** Navbar ********************* */

.Navbar {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px auto;
}


.Navbar-Title, .Navbar-Links {
    
}

.Navbar-Title {
    font-size: 35px;
    cursor: pointer;
   
}

.Navbar-Links {
    width: 100%;
    display: flex;
    justify-content: center;
}

.Navbar-Link {
    font-size: 22px;
    margin: 10px 35px;
    text-decoration: none;
    font-family: 'Open sans', sans-serif;
    color: black;
    font-weight: bold;
}


.Navbar-Link::after {
    width: 0%;
    height: 2px;
    margin: 3px auto;
    content: '';
    display: block;
    background-color: black;
    transition: all 0.4s ease-out;
}

.Navbar-Link:hover::after {
    width: 70%;
}

