.Carousel {
    height: 100vh;
    top: 0px;
}

.Time-Text {
    position: absolute;
    z-index: 1;
}

.Right-Section {
    width: 39%;
    height: fit-content;
    float: right;
    /* Experimental */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Location {
    font-size: 32px;
    width: 80%;
    margin: 0 auto;
}

.Date {
    font-size: 26px;
}

.RSVP-Button {
    width: 45%;
    font-size: 20px;
    height: 40px;
    background-color: inherit;
    border: 1px solid  black;
    border-radius: 0.2rem;
    margin-top: 30px;
    cursor: pointer;
    transition: 0.1s all ease;

    color: black;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
}


.RSVP-Button:hover {
    background-color: black;
    color: white;
    
}

.About {
    width: 100%;
    height: fit-content;
    background-color: white;
}

.About h1 {
    font-family: 'Alegreya Sans SC', sans-serif;
    text-align: center;
    margin-top: 50px;
    font-size: 25px;
}

.About hr {
    width: 25%;
    margin: 20px auto;  
}


.Video-Placeholder {
    width: 79%;
    height: 250px;
    margin: 0 auto;
    margin-top: 100px;
    margin-bottom: 100px;
}





@media only screen and (min-width: 250px) {
    .Carousel {
        width: 100%;
    }

    .Right-Section {
        width: 100vw;
    }

    .Video-Placeholder {
        width: 75%;
        height: fit-content;
    }

    .Gift-Text {
        font-size: 14px;
    }
}

@media only screen and (min-width: 600px) {
    .Video-Placeholder {
        max-width: 400px;
        height: 220px;
    }
}


@media only screen and (min-width: 1200px) {
    .Carousel {
        display: block;
        position: fixed;
        float: left;
        width: 61%;
    }

    .Right-Section {
        width: 39%;
    }

    .Video-Placeholder {
        width: 79%;
    }

    .Gift-Text {
        font-size: 17px;
    }
}