.Survey-Container {
    width: 100%;
    height: fit-content;
    margin: 0 auto;
    padding: 30px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.Shared-Text {
    margin: 20px auto;
    font-size: 16px;
    font-family: 'Open sans', sans-serif;
    resize: none;
    width: 100%;
    padding: 10px;
    position: relative;
    margin-bottom: 40px;;
}

.Test-Image {
    background: url(../../images/image8.jpeg);
    background-size: cover;
    width: 90%;
    margin: 40px;
    max-width: 500px;;
    height: 300px;
    opacity: 0.6;
}

.Text-Area-Container {
    width: 80%;
    position: relative;
    display: flex;
    justify-content: center;
}

.Text-Area-Container p {
    margin: 0;
    position: absolute;
    right: 5px;
    top: -5px;
}

.Text-Area-Message {
    width: 80%;
}


.Survey-Submit-Button {
    position: relative;
    background-color: white;
    border: 1px solid black;
    margin: auto;

    border-radius: 0.3rem;
    font-size: 18px;
    padding: 5px 15px;
    cursor: pointer;
    transition: all 0.2s ease;
    
}


.Survey-Submit-Button:hover {
    background-color: black;
    color: white;
}

@media only screen and (min-width: 250px) {
    .Text-Area-Container {
        max-width: 500px;
    }

    .Shared-Text {
        font-size: 13px;
        
    }


    .Text-Area-Container p {
        font-size: 13px;
    }

    .Text-Area-Message {
        width: 80%;
    }
}

@media only screen and (min-width: 1100px) {
    .Test-Image {
        display: none;
    }
}