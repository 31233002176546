.Results-Container {
    width: 80%;
    height: 80vh;
    border: 1px solid black;
    margin: auto;
    display: grid;
    grid-template-columns: 20% 20% 20% 40%;
    grid-auto-rows: 100px;
    overflow: scroll;
    padding: 10px;
    border-radius: 0.4rem;

}

.Results-Container h1 {
    margin: 0;
    padding: 0;
    font-family: 'Alegreya Sans SC', sans-serif;
    margin-top: 5px;
}


.Results-Container h1:nth-child(4) {
    display: flex;
    justify-content: center;
}

.Results-Container p {
    text-align: left;
}


.Result {
    grid-column: 1 / 5;
    border: 1px solid black;
    display: grid;
    grid-template-columns: 20% 20% 20% 40%;
    height: 100%;
}

.Result p {
    overflow-y: scroll;
    display: flex;
    align-items: center;
    text-indent: 10px;;
}


.Result p:nth-child(4) {
    font-size: 14px;
}