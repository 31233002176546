.QA {
    width: 100%;
    text-align: center;
}


.QA p {
    font-size: 'Open sans', sans-serif;
}

.Section-Header {
    font-family: 'Alegreya Sans SC', sans-serif;
    font-size: 23px;
}

.Section-Border {
    width: 20%;
    margin: auto;
    margin-bottom: 30px;
}

.QA-Message {
    width: 80%;
    margin: auto;
    margin-bottom: 40px;
    font-size: 17px;
    text-align: left;
    text-indent: 20px;
    font-family: 'Open sans',  sans-serif;
}


.Questions-Container {
    width:  70%;
    margin: 50px auto;
}

.QA-Question {
    text-align: left;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 30px;
    font-size: 17px;
    font-family: 'Open sans',  sans-serif;
}

.QA-Question strong {
    margin-right: 15px;
}


.QA-Response {
    width:  70%;
    font-size: 16px;
    margin: 0 auto;
    line-height: 25px;
    text-indent: 20px;
    margin-bottom: 50px;
    font-family: 'Open sans',  sans-serif;
}


@media only screen and (min-width: 250px) {

    .QA-Message {
        font-size: 14px;
    }

    .QA-Question {
        font-size: 14px;
    }

    .QA-Response {
        font-size: 12px;
    }
}

@media only screen and (min-width: 700px) {

    .QA-Message {
        font-size: 17px;
    }

    .QA-Question {
        font-size: 18px;
    }

    .QA-Response {
        font-size: 16px;
    }
}


