/* Story Component */
.Story {
    width: 100%;
    height: fit-content;
    background-color: white;
    text-align: center;
    font-family: 'Alegreya Sans SC', sans-serif;
    font-weight: 900;
    font-size: 25px;  
    padding: 20px 0px;
}


.Story h1 {
    font-size:  25px;
}

.Story-Text {
    text-indent: 20px;
    font-size: 15.5px;
    font-family: 'Open sans',  sans-serif;
    width: 70%;
    line-height: 27px;
    font-weight: 300;
    margin: 0 auto;
    text-align: left;
}




.Story-Text:last-child {
    margin-bottom: 0px;
}

.Story hr {
    width: 15%;
    margin: 20px auto;
}

.Story-Image {
    width: 460px;
    height: 400px;
    background: url(../../../images/festival.jpeg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: auto;
    margin-top: 100px;
    margin-bottom: 75px;
}



@media only screen and (min-width: 250px) {
    .Story-Image {
        width: 300px;
        height: 250px;
    }

    .Story-Text {
        width: 80%;
    }
}


@media only screen and (min-width: 450px) {
    .Story-Image {
        
    }
}


@media only screen and (min-width: 700px) {
    .Story-Text {
        width: 70%;
    }

    .Story-Image {
        width: 350px;
        height: 350px;
        
    }
}