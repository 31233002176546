.RSVP-Prompt {
    width: 80%;
    max-width: 550px;
    height: 600px;
    box-shadow: 5px 10px 10px rgba(0,0,0,0.17), -5px -10px 10px rgba(0,0,0,0.14);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    float: right;
    background-color: white;
}

.RSVP-Form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.RSVP-Prompt h1, p {
    text-align: center;
}

.RSVP-Prompt input {
    height: 30px;
    margin-bottom: 20px;
    text-indent: 15px;
    font-size: 17px;
    padding: 5px 0px;
    font-family: 'Open sans', sans-serif;
    border: 1px solid black;
    border-radius: 0.3rem;
}

.RSVP-Prompt input[type=text]:first-child {
    margin-right: 20px;
}

.Input-Container {
    position: relative;
    width: fit-content;
    height: fit-content;
}

.RSVP-Form input[type=email] {
    width: 50%;
    margin-bottom: 30px;
    margin-top: 10px;
}



.Error-Message {
    border: 1.5px solid red;
    background-color: rgba(255,0,0,0.2);
    margin-top: -15px;
    color: red;
    font-size: 17px;
    padding: 10px 15px;
    border-radius: 0.3rem;
    animation: fadeOut 1s forwards;
    animation-delay: 5s;
    width: 50%;
    min-width: 270px;
}

.RSVP-Title {
    font-family: 'Alegreya Sans SC', sans-serif;
    font-size: 35px;
}

.RSVP-Text {
    font-family: 'Open sans', sans-serif;
    margin: 10px;
}



.RSVP-Text:nth-child(4) {
    margin-bottom: 40px;
}

.Next-Button {
    width: 150px;
    font-size: 18px;
    padding: 7px;
    background-color: white;
    border: 1px solid black;
    border-radius: 0.3rem;
    cursor: pointer;
    transition: all 0.125s;
    margin-top: 10px;
}

.Next-Button:hover {
    background-color: black;
    color: white;
}

@media only screen and (min-width: 250px) {
    .RSVP-Title {
        font-size: 24px;
    }

    .RSVP-Text {
        font-size: 15px;
    }

    .RSVP-Prompt {
        width: 90%;
        height: 520px;
       
    }

    .RSVP-Prompt input {
        height: 20px;
        font-size: 13px;
    }

    .Name-Inputs {
        margin: auto;
        width: fit-content;
        width: 50%;
        
    }

    .Name-Inputs input {
        width: 100%;
        display: block;
    }

    .RSVP-Text:nth-child(4) {
        margin-bottom: 30px;
    }



    .RSVP-Form input[type=email] {
        width: 50%; 
    }

    .Next-Button {
        font-size: 14px;
        width: 120px;
        margin: 0;
    }
}

@media only screen and (min-width: 1200px) {
    .RSVP-Prompt {
        width: 90%;
        height: 520px;
        max-width: 500px;
    }
    

    .Name-Inputs {
        width: 100%;
        display: flex;
        justify-content: center;
    }


    .Name-Inputs input {
        width: 40%;
        max-width: 150px;
        display: inline;
        font-size: 16px;
        height: 25px;
    }

    .RSVP-Form input[type=email] {
        width: 40%; 
    }

    .RSVP-Form input[type=email] {
       font-size: 16px;
       height: 25px;
    }
}