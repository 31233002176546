.Event-Summary-Container {
    height: 100vh;
    width: 100%;
    background: url('../../../images/leave.jpeg');
    background-size: cover;
    position: relative;
    opacity: 0.95;
}


.Couple {
    margin-top: -50px;
    margin-bottom: 50px;
}

.RSVP-Container {
    width: 77%;
    max-width: 500px;
    background-color: white;
    opacity: 0.9;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    

    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items:  center;
    font-family: 'Alegreya Sans SC', sans-serif;
}

.Time {
    font-size: 19px;
}


@media only screen and (min-width: 250px) {
    .RSVP-Container {
        width: 90%;
        height: 480px;
    }

    .Couple {
        font-size: 24px
    }

    .Location {
        font-size: 16px;
    }

    .Date {
        font-size: 16px;
    }

    .Time {
        font-size: 16px;
    }
}


@media only screen and (min-width: 600px) {
    .RSVP-Container {
        height: 600px;
        width: 85%;
    }


    .Couple {
        font-size: 2em;
    }

    .Location {
        font-size: 28px;
    }


    .Date, .Time {
        font-size: 24px;
    }
}

@media only screen and (min-width: 900px) {
    .RSVP-Container {
        width: 79%;
    }
}